import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faShuttle } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import mision from "../media/pictures/mision.png";
import vision from "../media/pictures/vision.png";

const QuienesSomos = () => {
  return (
    <QuieneSomosStyles>
      <div id="quienes-somos-dos">
        <div className="titulo" id="quienes-somos">
          <div className="empty"></div>
          <h2>Acerca de Nosotros</h2>
          <div className="circles">
            <div className="small-green-circle"></div>
            <div className="small-green-circle"></div>
            <div className="small-green-circle"></div>
            <div className="small-green-circle"></div>
            <div className="small-green-circle"></div>
            <div className="small-green-circle"></div>
          </div>
        </div>
        <div className="grid-container-somos">
          <div className="item-1">
            <div className="container-image-rectangular">
              <div className="container-image-rectangular-inside"></div>
            </div>
          </div>
          <div className="item-2">
            <h2>¿Quiénes somos?</h2>
            <p>
        

              Somos un despacho que presta servicios de contabilidad, asesoría fiscal, soluciones en facturación y
asesoría administrativa. Contamos con alianzas estratégicas que pueden apoyar su negocio en el
tema legal y de auditoría.
            </p>
          </div>
          {/* <div className="item-3">
            <h2>Nuestra Historia</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
              ab nam consequatur aliquam officia rerum doloremque? Cum,
              repellat! Rem, quia!
            </p>
          </div>
          <div className="item-4">
            <div className="container-image-rectangular">
              <div className="container-image-rectangular-inside"></div>
            </div>
          </div> */}
        </div>
        <br />
        <br />
        <div className="item-2">
          <h2>Nuestra Historia</h2>
          <br />
          <p>
              

          Sabías que nuestra historia comenzó hace más de 13 años. Somos un despacho contable que
comenzó con la asesoría y servicios de contabilidad para micronegocios de servicios, los cuales
fueron el punto de partida para poder iniciar de manera más formal nuestro servicio y así poder
comenzar la empresa de manera formal.
<br />
En 2015 la capacitación en temas administrativos y fiscales permitió que la curva de crecimiento y
aprendizaje de nuestros servicios se elevará teniendo clientes con pequeñas y medianas
empresas.
<br />
Actualmente contamos con clientes que han estado con nosotros desde hace más de diez años, lo
que ha permitido ver su crecimiento como empresa y su rentabilidad financiera, con el apoyo del
servicio y asesoría que se les presta.
<br />
Hoy por hoy se cuenta con clientes que superan las esferas de los que conocemos como PyMEs, lo
que nos obliga y sobre todo nos compromete a tener las mejores estrategias y conocimientos en el
ámbito fiscal, financieros, contables y administrativo.

          </p>
          <br />
      
        </div>
        <div className="mision-vision-box">
          <div className="mision-vision">
            <img src={mision} alt="mision" width="100" />
            <div>
              <h3>Misión</h3>
              <p>
                Crear, mantener y mejorar las estrategias, contables, fiscales,
                financieras y administrativas que permitan que su empresa y/o
                negocio cumpla con las disposiciones vigentes.
              </p>
            </div>
          </div>
          <div className="mision-vision">
            <img src={vision} alt="visión" width="100" height="80" />
            <div>
              <h3>Visión</h3>

              <p>
              Ser una firma que sea reconocida por los servicios que se les brinda a los clientes, a través de su
servicio personalizado y profesional, además de ser la primera opción para empresas pymes y
familiares, las cuales son la base de la economía de nuestro país.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <footer>
        <h3>Footer aviso de privacidad</h3>
      </footer> */}
    </QuieneSomosStyles>
  );
};

export default QuienesSomos;

const QuieneSomosStyles = styled.div`
  .mision-vision img {
    object-fit: contain;
  }
  @media screen and (max-width: 900px) {
  }
  .empty {
    border: black solid 1px;
    height: 2rem;
    width: 10px;
    visibility: hidden;
  }

  footer {
    border: black solid 1px;
    background: #0a0a2c;
    color: white;
    padding: 1.1rem 0;
  }
  footer h3 {
    font-size: 0.9rem;
    font-weight: lighter;
    font-family: "MyriadPro-Regular";
  }
  .coffe {
    font-size: 5rem;
    margin-bottom: 0.8rem;
  }
  .mision-vision-box {
    /* border: red solid 1px; */
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 5rem;
  }
  .mision-vision {
    /* border: black solid 1px; */
    width: 30%;
    padding-top: 1rem;
    font-family: "MyriadPro-Regular";
  }

  .mision-vision div h3 {
    margin-bottom: 0.8rem;
    color: #54c86e;
    font-size: 1.5rem;
    font-family: "MyriadPro-Regular";
  }
  .mision-vision div p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    width: 90%;
    margin: 0 auto;
    font-family: "MyriadPro-Regular";
  }
  .titulo h2 {
    color: #241448;
    font-family: "MyriadPro-Regular";
  }
  .item-2 h2 {
    margin-bottom: 0.5rem;
    color: #54c86e;
    font-size: 1.6rem;
    font-family: "MyriadPro-Regular";
  }
  .item-2 p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    width: 70%;
    margin: 0 auto;
  }
  .item-3 h2 {
    margin-top: 1.5rem;
    color: #54c86e;
    font-size: 1.6rem;
  }
  .item-3 p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    width: 70%;
    margin: 0 auto;
  }
  .circles {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .titulo {
    margin-bottom: 1.2rem;
    display: flex;
    /* border: red solid 1px; */
    width: 20%;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-left: 3.8rem;
    font-family: "MyriadPro-Regular" !important;
  }
  .titulo h2 {
    font-size: 2.8rem;
    /* border: black solid 1px; */
    width: 80%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 0.8rem;
  }
  .small-green-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #54c86e;
  }
  .container-image-rectangular {
    border: black solid 1px;
    width: 80%;
    height: 200px;
    margin: 0 auto;
    border-radius: 2.2rem;
    background: #0a0a2c;
  }
  .container-image-rectangular-inside {
    /* border: black solid 1px; */
    width: 80%;
    height: 200px;
    margin: 0 auto;
    background-image: url("https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }

  .grid-container-somos {
    width: 80%;
    margin: 0 auto;
    display: grid;
    /* border: red solid 1px; */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      ". a"
      "b a"
      ". c";
  }
  .item-1 {
    grid-area: a;
    /* border: blue solid 1px; */
  }
  .item-2 {
    grid-area: b;
  }
  .item-3 {
    grid-area: c;
  }
  .item-4 {
    grid-area: d;
  }
  @media screen and (max-width: 900px) {
    .container-image-rectangular {
      border: black solid 1px;
      width: 80%;
      height: 150px;
      margin: 0 auto;
      border-radius: 2.2rem;
      background: #0a0a2c;
    }
    .container-image-rectangular-inside {
      width: 80%;
      height: 150px;
      margin: 0 auto;
      background-image: url("https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover;
    }
    .titulo {
      width: 80%;
      margin: 1rem auto 2rem auto;
    }
    .grid-container-somos {
      width: 100%;

      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "a a"
        "b b"
        "c c"
        "d d";
    }
    .item-4 {
      margin-top: 2rem;
    }
    .mision-vision-box {
      /* border: black solid 1px; */
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }
    .mision-vision {
      width: 80%;
      margin: 0 auto;
    }
    .item-2 h2,
    .item-3 h2 {
      margin-bottom: 1rem;
    }
  }
  .mision-vision div p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    width: 90%;
    margin: 0 auto;
  }
`;
