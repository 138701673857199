import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = "";
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

const NavMobile = (props) => {
  const {
    isMenuActive,
    setIsMenuActive,
    routerCirclesState,
    setRouterCirclesState,
    preventScroll,
  } = props;
  const [navBackground, setNavBackground] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavBackground(true);
    } else {
      setNavBackground(false);
    }
    // console.log(window.scrollY);
  };

  window.addEventListener("scroll", changeBackground);

  const { lockScroll, unlockScroll } = useScrollLock();
  const aboutFunction = (numberCircle) => {
    setRouterCirclesState(() => {
      console.log("ARREGLO DEVUELTO");
      return routerCirclesState.map((item) =>
        item.id == numberCircle
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      );
    });
  };
  const bloquearScroll = () => {
    console.log("BLOQUENADO SCROLL");
    document
      .querySelector(".scrollable")
      .addEventListener("scroll touchmove mousewheel wheel", () => {
        console.log("ESTAS HACIENDO SCROLL");
        return preventScroll;
      });
    console.log("FIN DE LA FUNCION");
  };
  return (
    <NavMobileStyles navBackground={navBackground}>
      {/* <h2>
        <FontAwesomeIcon icon={faBars} className="bars" />
      </h2> */}
      {!isMenuActive && (
        <div
          className={
            navBackground ? "active-nav bars-box-father" : "bars-box-father"
          }
        >
          <button
            className="bars-box"
            onClick={() => {
              bloquearScroll();
              console.log("GIVING CLICK");

              setIsMenuActive(!isMenuActive);
            }}
          >
            <FontAwesomeIcon icon={faBars} className="bars" />
          </button>
        </div>
      )}

      {isMenuActive && (
        <div className="menu-mobile">
          <div className="close-box">
            <button
              className="bars-box"
              onClick={() => {
                document
                  .querySelector(".scrollable")
                  .removeEventListener(
                    "scroll touchmove mousewheel wheel",
                    preventScroll
                  );
                setIsMenuActive(!isMenuActive);
              }}
            >
              <FontAwesomeIcon icon={faXmark} className="bars" />
            </button>
          </div>
          <div className="menu-links">
            <Link
              // activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setIsMenuActive(false)}
            >
              Home
            </Link>
            <Link
              activeClass="active"
              to="quienes-somos"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setIsMenuActive(false)}
            >
              Acerca de
            </Link>
            <Link
              to="servicios"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setIsMenuActive(false)}
            >
              ¿Qué ofrecemos?
            </Link>
            <Link
              to="porque-nosotros"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setIsMenuActive(false)}
            >
              ¿Por qué nosotros?
            </Link>
            <Link
              to="contacto"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => {
                console.log("DISTE CLICK");
                setIsMenuActive(false);
              }}
            >
              Contacto
            </Link>
          </div>
        </div>
      )}
    </NavMobileStyles>
  );
};

export default NavMobile;

const NavMobileStyles = styled.nav`
  width: 100%;
  transition: all linear 0.4s;
  background: ${(props) => (props.navBackground ? "white" : "")};
  box-shadow: ${(props) =>
    props.navBackground ? "1px 3px 11px -3px rgba(0, 0, 0, 0.47)" : ""};
  position: fixed;
  top: 0rem !important;

  .active {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .menu-links {
    /* border: black solid 1px; */
  }
  .close-box {
    display: flex;
    justify-content: right;
  }
  .bars-box-father {
    display: flex;
  }
  position: fixed;
  z-index: 10;
  .bars-box {
    margin: 1rem 1rem 0.3em 1.2rem;
  }
  .menu-links {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  a {
    color: #1e2e59;
    margin: 1.3rem 1rem 0.8rem 1.3rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .menu-links ul li {
    padding: 1rem;
    font-size: 1.2rem;
    color: #1e2e59;
    font-weight: bold;
  }
  .menu-mobile {
    /* border: black solid 1px; */
    width: 80%;
    height: calc(100vh + 0.3rem);
    z-index: 100 !important;
    background: white;
    position: fixed;
    top: -0.3rem;
    -webkit-box-shadow: 1px 3px 11px -3px rgba(0, 0, 0, 0.47);
    box-shadow: 1px 3px 11px -3px rgba(0, 0, 0, 0.47);
  }
  .bars-box {
    background: none;
    border: none;
    font-size: 2.6rem;
    cursor: pointer;
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
`;
