import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import welcomevideo from "../media/videos/welcomevideo.mp4";
import logoIta from "../media/pictures/ita-logo-dos.png";
import ReactDOM from "react-dom/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
// import eyeImage from "../media/icons/eye-solid.svg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContentRouter from "./ContentRouter";
import QuienesSomos from "./QuienesSomos";
import Servicios from "./Servicios";
import PorQueNosotros from "./PorQueNosotros";
import Contacto from "./Contacto";
import countapi from "countapi-js";
import NavMobile from "../components/NavMobile";

import useVH from "react-vh";
import Noticias from "./Noticias";

const widthImgLogo = 180;
function preventScroll(e) {
  console.log("PREVINIENDO SCROLL????????????");
  e.preventDefault();
  e.stopPropagation();

  return false;
}
const Welcome = () => {
  const [routerCirclesState, setRouterCirclesState] = useState([
    {
      id: 1,
      isActive: true,
    },
    {
      id: 2,
      isActive: false,
    },
    {
      id: 3,
      isActive: false,
    },
    {
      id: 4,
      isActive: false,
    },
  ]);
  const [visitorCounter, setVisitorCounter] = useState(0);
  const [isMenuActive, setIsMenuActive] = useState(false);
  useEffect(() => {
    console.log("CONSULTANDO DATOS SOBRE CONTADOR: ");
    countapi.visits().then((result) => {
      console.log("DENTRO DE FUNCION CONTADOR");
      console.log(result.value);
      setVisitorCounter(result.value + 1000);
    }).catch((error)=>{
      console.log("No se pudo consultar el contador"); 
    });
    console.log("Fin del useEffect contador"); 
  }, []);
  const changeLogo = () => {

    // if (window.scrollY >= 60) {
    //   setNavbarLogo(logoBlue)
    // } else {
    //   setNavbarLogo(logo)
    // }
  };
  useVH();
  // window.addEventListener("wheel",)

  useEffect(() => {
    console.log("useEffect");
    console.log("USEVH");

    changeLogo();
    // adding the event when scroll change Logo

    window.addEventListener("scroll", changeLogo);
  });
  return (
    <BrowserRouter>
      <WelcomeComponent isMenuActive={isMenuActive}>
        <div
          className="scrollable"
          onClick={() => {
            console.log("DISTE CLICK");
          }}
        >
          <div>
            <div className="visitor-counter">
              <h2>
                <FontAwesomeIcon icon={faEye} className="coffe" />
                {"   "}
            
                <a href="#" target="_blank"><img src="https://www.cutercounter.com/stats.php?id=hvexpdfqx&nd=1&style=10" border="0" alt="website counter"/></a>


              </h2>
            </div>

            <div className="nav-dos"></div>
            <Navbar
              routerCirclesState={routerCirclesState}
              setRouterCirclesState={setRouterCirclesState}
            />

            <NavMobile
              isMenuActive={isMenuActive}
              setIsMenuActive={setIsMenuActive}
              routerCirclesState={routerCirclesState}
              setRouterCirclesState={setRouterCirclesState}
              preventScroll={preventScroll}
            />
          </div>
          <div className="video-container" id="home" name="home">
            <video
              src={welcomevideo}
              autoPlay={true}
              muted={true}
              loop
              playsInline
              className="video-welcome"
            ></video>

            <img
              src={logoIta}
              alt="imagen circular"
              width={widthImgLogo}
              height={widthImgLogo}
              className="circular-logo-dos"
            />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <Noticias />
          <QuienesSomos />
          <Servicios />
          <PorQueNosotros />
          <Contacto />
        </div>
        <div
          className={isMenuActive ? "black-screen" : ""}
          onClick={() => {
            console.log("YOU CLICKED BLACK SCREEN");
            setIsMenuActive(false);
          }}
        ></div>
      </WelcomeComponent>
    </BrowserRouter>
  );
};

export default Welcome;

const WelcomeComponent = styled.div`
  position: relative;
  height: 100%;
  overflow: "auto";

  /* .scrollable {
    height: 100%;
    overflow: ${(props) => (props.isMenuActive ? "auto" : "")};
  } */
  .black-screen {
    position: fixed;
    top: 0px;
    left: 0px;
    /* right: 0px;
    bottom: 0px; */
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    /* z-index: 9; */
  }

  .nav-dos {
    width: 150px;
    height: 150px;
    position: fixed;
  }
  .visitor-counter {
    position: relative;
    z-index: 5 !important;
  }
  .coffe {
    font-size: 1.5rem;
    color: black;
  }
  /* .visitor-counter h2 {
    color: black;
    border: black solid 1px;
    width: 120px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    margin-right: 0.6rem;
  } */
  .visitor-counter {
    width: 150px;
    /* border: black solid 1px; */
    position: fixed;
    right: 0;
    bottom: 0;
  }
  @media screen and (max-width: 900px) {
  }
  .cicle-container {
    width: ${widthImgLogo}px;
    height: ${widthImgLogo}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* background: blue; */
  height: 100vh;
  /* background-image: url("https://images.pexels.com/photos/2739013/pexels-photo-2739013.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"); */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  .circular-logo {
    position: absolute;
    top: calc(50% - (${widthImgLogo / 2}px));
    left: calc(50% - (${widthImgLogo / 2}px));
    border-radius: 50%;
    padding: 0.5rem;
    background-color: white;
  }
  .circular-logo-dos {
    position: absolute;
    top: calc(50% - (${widthImgLogo / 2}px));
    left: calc(50% - (${widthImgLogo / 2}px));
    width: ${widthImgLogo}px;
    height: ${widthImgLogo}px;
  }

  /* .video-welcome {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    height: 100vh;
    width: auto;
    border: black solid 1px;
    z-index: -1;
  } */
  /* video {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  } */
  video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;

    max-height: 100vh;
    width: 100%;
    opacity: 0.7;
    /* height: 100% !important;
    max-height: none !important;
    max-width: none !important;
    min-height: auto !important;
    min-width: auto !important;
    width: 100% !important; */
  }
  @media screen and (max-width: 900px) {
    video {
      min-height: 100vh;
      min-width: 100vw;
    }
  }
`;
