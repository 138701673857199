import "./App.css";
import Welcome from "./pages/Welcome";
import QuienesSomos from "./pages/QuienesSomos";
import Servicios from "./pages/Servicios";
import PorQueNosotros from "./pages/PorQueNosotros";
import Contacto from "./pages/Contacto";
import React, { useState } from "react";
import styled from "styled-components";

function App() {
  return (
    <div className="App">
      <Welcome />
    </div>
  );
}

export default App;
