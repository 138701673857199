import { React, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

// style
import "../css/Servicios.css";
import { useEffect } from "react";

// images
import ADMIN from "../media/pictures/administrativo.png";
import ALIANZAS from "../media/pictures/alianzas.png";
import CONTA from "../media/pictures/contabilidad.png";
import FISCAL from "../media/pictures/fiscal.png";
import TECINF from "../media/pictures/tecInf.png";

const Servicios = () => {
  useEffect(() => {
    setTimeout(() => {
      const firstS = document.getElementById("first-serv");
      const secondS = document.getElementById("second-serv");
      const thirdS = document.getElementById("third-serv");
      const fourthS = document.getElementById("fourth-serv");
      const fifthS = document.getElementById("fifth-serv");

      const firstT = document.getElementById("first-triangle");
      const secondT = document.getElementById("second-triangle");
      const thirdT = document.getElementById("third-triangle");
      const fourthT = document.getElementById("fourth-triangle");
      const sixthT = document.getElementById("sixth-triangle");

      const infoContainer = document.getElementById("serv-info");

      const triangles1 = document.getElementById("triangles-sec1");
      const triangles2 = document.getElementById("triangles-sec2");

      const navegador = navigator.userAgent;

      document.getElementById("serv-tittle").innerHTML = `
                    <h2>Fiscal</h2>
                `;

      document.getElementById("info-text").innerHTML = `
            <ul>
                <li>Contabilidad para personas físicas y morales</li>
                <li>Trámites ante el SAT</li>
                <li>Solicitud de devoluciones de saldo a favor</li>
                <li>Cálculo de impuestos</li>
                <li>Declaraciones mensuales informativas y anuales</li>
                <li>Elaboración de Estados financieros</li>
                <li>Estrategias fiscales</li>
            </ul>`;

      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        firstS.addEventListener("click", function () {
          firstT.firstChild.className = "tleft-triangle";

          thirdT.firstChild.className = "hidden-triangle";

          infoContainer.style.gridRow = "2";

          secondS.style.gridRow = "1";
          firstS.style.gridRow = "1";
          thirdS.style.gridRow = "3";
          fourthS.style.gridRow = "3";
          fifthS.style.gridRow = "4";

          triangles1.style.gridRow = "2";

          document.getElementById("serv-tittle").innerHTML = `
                    <h2>Fiscal</h2>
                `;

          document.getElementById("info-text").innerHTML = `
                <ul>
                    <li>Contabilidad para personas físicas y morales</li>
                    <li>Trámites ante el SAT</li>
                    <li>Solicitud de devoluciones de saldo a favor</li>
                    <li>Cálculo de impuestos</li>
                    <li>Declaraciones mensuales informativas y anuales</li>
                    <li>Elaboración de Estados financieros</li>
                    <li>Estrategias fiscales</li>
                </ul>`;
        });

        secondS.addEventListener("click", function () {
          thirdT.firstChild.className = "tright-triangle";

          firstT.firstChild.className = "hidden-triangle";

          infoContainer.style.gridRow = "2";

          secondS.style.gridRow = "1";
          firstS.style.gridRow = "1";
          thirdS.style.gridRow = "3";
          fourthS.style.gridRow = "3";
          fifthS.style.gridRow = "4";

          triangles1.style.gridRow = "2";

          document.getElementById("serv-tittle").innerHTML = `
                    <h2>Colaboración</h2>
                `;

          document.getElementById("info-text").innerHTML = `
                <ul>
                    <li>Asesoría en la elaboración de contratos o convenios laborales.</li>
                    <li>Asesoría en el cumplimiento de la NOM35.</li>
                    <li>Alianzas con colaboradores legales para el apoyo de asuntos de esta índole.</li>
                </ul>`;
        });

        thirdS.addEventListener("click", function () {
          thirdT.firstChild.className = "hidden-triangle";

          firstT.firstChild.className = "tleft-triangle";

          infoContainer.style.gridRow = "3";

          firstS.style.gridRow = "1";
          secondS.style.gridRow = "1";
          thirdS.style.gridRow = "2";
          fourthS.style.gridRow = "2";
          fifthS.style.gridRow = "4";

          triangles1.style.gridRow = "3";

          document.getElementById("serv-tittle").innerHTML = ``;

          document.getElementById("info-text").innerHTML = `
                    <div class="ita-srv-info-tittle">
                        <h2>Nómina</h2>
                    </div>

                    <ul>
                        <li>Cálculo de nómina.</li>
                        <li>Control de incidencias.</li>
                        <li>Emisión y timbrado de nómina.</li>
                        <li>Altas, bajas y modificaciones ante el IMSS.</li>
                        <li>Altas, bajas y modificaciones en el padrón de contribuyentes local para el pago del impuesto sobre nómina.</li>
                        <li>Declaraciones mensuales, anuales e informativas relacionadas con la nómina ante el IMSS, SAT y contribuciones locales.</li>
                        <li>Seguimientos de pagos de cuotas obrero patronales en ISDE, SUA, SIPARE, MI PORTAL EMPRESARIAL INFONAVIT.</li>
                        <li>Cálculo, emisión y timbrado de finiquitos y liquidaciones.</li>
                    </ul>
                        
                    <div class="ita-srv-info-tittle">
                        <h2>Asesoramiento Financiero</h2>
                    </div>

                    <ul>
                        <li>Razón de negocio.</li>
                        <li>Análisis de estados financieros.</li>
                        <li>Estrategia para implementar la utilidad de la empresa a través de controles administrativos.</li>
                    </ul>

                    <div class="ita-srv-info-tittle">
                        <h2>Atención Personalizada</h2>
                    </div>

                    <ul>
                        <li>Asalariados.</li>
                        <li>Profesionistas de las ramas de salud o ramas de enseñanza, etc.</li>
                        <li>Arrendadores.</li>
                        <li>RESICO FISICSS Y MORALES.</li>
                    </ul>
                `;
        });

        fourthS.addEventListener("click", function () {
          thirdT.firstChild.className = "tright-triangle";

          firstT.firstChild.className = "hidden-triangle";

          infoContainer.style.gridRow = "3";

          secondS.style.gridRow = "1";
          firstS.style.gridRow = "1";
          thirdS.style.gridRow = "2";
          fourthS.style.gridRow = "2";
          fifthS.style.gridRow = "4";

          triangles1.style.gridRow = "3";

          document.getElementById("serv-tittle").innerHTML = ``;

          document.getElementById("info-text").innerHTML = `
                    <div class="ita-srv-info-tittle">
                        <h2>Contabilidad</h2>
                    </div>

                    <p>Personas físicas, personas morales y contabilidad electrónica.</p>
                       
                    <div class="ita-srv-info-tittle">
                        <h2>Soluciones en facturación</h2>
                    </div>

                    <ul>
                        <li>Asesoría en sistemas de facturación.</li>
                        <li>Capacitación en la correcta elaboración de CFDI.</li>
                        <li>Elaboración de facturas electrónicas a profesionistas.</li>
                    </ul>
                `;
        });

        fifthS.addEventListener("click", function () {
          firstT.firstChild.className = "tleft-triangle";

          thirdT.firstChild.className = "hidden-triangle";

          infoContainer.style.gridRow = "4";

          fifthS.style.gridRow = "3";
          secondS.style.gridRow = "1";
          fourthS.style.gridRow = "2";
          firstS.style.gridRow = "1";
          thirdS.style.gridRow = "2";

          triangles1.style.gridRow = "4";

          document.getElementById("serv-tittle").innerHTML = `
                    <h2>Tecnología</h2>
                `;

          document.getElementById("info-text").innerHTML = `

                    <p>Contamos con software que permite la contabilización correcta y eficiente para
                    una mejor toma de decisiones, lo que facilita información confiable en todo momento.
                        <br/><br/>
                    Nuestros software:</p>

                    <ul>
                        <li>Contable: contabiliza las operaciones a través de tus CFDI, además de generar los archivos de envío de la ocntabilidad electrónica.</li>
                        <li>Nómina: Cálculo, control y timbrado de CFDI de nómina.</li>
                        <li>Facturación: Emite los CFDI cumpliendo con los requerimientos técnicos que emite el SAT.</li>
                    </ul>
                `;
        });
      } else {
        firstS.addEventListener("mouseover", function () {
          firstT.firstChild.className = "srv-img tleft-triangle";

          secondT.firstChild.className = "hidden-triangle";
          thirdT.firstChild.className = "hidden-triangle";
          fourthT.firstChild.className = "hidden-triangle";
          sixthT.firstChild.className = "hidden-triangle";

          document.getElementById("serv-tittle").innerHTML = `
                    <h2>Fiscal</h2>
                `;

          document.getElementById("info-text").innerHTML = `
                <ul>
                    <li>Contabilidad para personas físicas y morales</li>
                    <li>Trámites ante el SAT</li>
                    <li>Solicitud de devoluciones de saldo a favor</li>
                    <li>Cálculo de impuestos</li>
                    <li>Declaraciones mensuales informativas y anuales</li>
                    <li>Elaboración de Estados financieros</li>
                    <li>Estrategias fiscales</li>
                </ul>`;
        });

        secondS.addEventListener("mouseover", function () {
          secondT.firstChild.className = "srv-img ttop-triangle";

          firstT.firstChild.className = "hidden-triangle";
          thirdT.firstChild.className = "hidden-triangle";
          fourthT.firstChild.className = "hidden-triangle";
          sixthT.firstChild.className = "hidden-triangle";

          document.getElementById("serv-tittle").innerHTML = `
                    <h2>Colaboración</h2>
                `;

          document.getElementById("info-text").innerHTML = `
                <ul>
                    <li>Asesoría en la elaboración de contratos o convenios laborales.</li>
                    <li>Asesoría en el cumplimiento de la NOM35.</li>
                    <li>Alianzas con colaboradores legales para el apoyo de asuntos de esta índole.</li>
                </ul>`;
        });

        thirdS.addEventListener("mouseover", function () {
          thirdT.firstChild.className = "srv-img tright-triangle";

          secondT.firstChild.className = "hidden-triangle";
          firstT.firstChild.className = "hidden-triangle";
          fourthT.firstChild.className = "hidden-triangle";
          sixthT.firstChild.className = "hidden-triangle";

          document.getElementById("serv-tittle").innerHTML = ``;

          document.getElementById("info-text").innerHTML = `
                    <div class="ita-srv-info-tittle">
                        <h2>Nómina</h2>
                    </div>

                    <ul>
                        <li>Cálculo de nómina.</li>
                        <li>Control de incidencias.</li>
                        <li>Emisión y timbrado de nómina.</li>
                        <li>Altas, bajas y modificaciones ante el IMSS.</li>
                        <li>Altas, bajas y modificaciones en el padrón de contribuyentes local para el pago del impuesto sobre nómina.</li>
                        <li>Declaraciones mensuales, anuales e informativas relacionadas con la nómina ante el IMSS, SAT y contribuciones locales.</li>
                        <li>Seguimientos de pagos de cuotas obrero patronales en ISDE, SUA, SIPARE, MI PORTAL EMPRESARIAL INFONAVIT.</li>
                        <li>Cálculo, emisión y timbrado de finiquitos y liquidaciones.</li>
                    </ul>
                        
                    <div class="ita-srv-info-tittle">
                        <h2>Asesoramiento Financiero</h2>
                    </div>

                    <ul>
                        <li>Razón de negocio.</li>
                        <li>Análisis de estados financieros.</li>
                        <li>Estrategia para implementar la utilidad de la empresa a través de controles administrativos.</li>
                    </ul>

                    <div class="ita-srv-info-tittle">
                        <h2>Atención Personalizada</h2>
                    </div>

                    <ul>
                        <li>Asalariados.</li>
                        <li>Profesionistas de las ramas de salud o ramas de enseñanza, etc.</li>
                        <li>Arrendadores.</li>
                        <li>RESICO FISICAS Y MORALES.</li>
                    </ul>
                `;
        });

        fourthS.addEventListener("mouseover", function () {
          fourthT.firstChild.className = "srv-img bleft-triangle";

          secondT.firstChild.className = "hidden-triangle";
          thirdT.firstChild.className = "hidden-triangle";
          firstT.firstChild.className = "hidden-triangle";
          sixthT.firstChild.className = "hidden-triangle";

          document.getElementById("serv-tittle").innerHTML = ``;

          document.getElementById("info-text").innerHTML = `
                    <div class="ita-srv-info-tittle">
                        <h2>Contabilidad</h2>
                    </div>

                    <p>Personas físicas, personas morales y contabilidad electrónica.</p>
                       
                    <div class="ita-srv-info-tittle">
                        <h2>Soluciones en facturación</h2>
                    </div>

                    <ul>
                        <li>Asesoría en sistemas de facturación.</li>
                        <li>Capacitación en la correcta elaboración de CFDI.</li>
                        <li>Elaboración de facturas electrónicas a profesionistas.</li>
                    </ul>
                `;
        });

        fifthS.addEventListener("mouseover", function () {
          sixthT.firstChild.className = "srv-img bbottom-triangle";

          secondT.firstChild.className = "hidden-triangle";
          thirdT.firstChild.className = "hidden-triangle";
          fourthT.firstChild.className = "hidden-triangle";
          firstT.firstChild.className = "hidden-triangle";

          document.getElementById("serv-tittle").innerHTML = `
                    <h2>Tecnología</h2>
                `;

          document.getElementById("info-text").innerHTML = `

                    <p>Contamos con software que permite la contabilización correcta y eficiente para
                    una mejor toma de decisiones, lo que facilita información confiable en todo momento.
                        <br/><br/>
                    Nuestros software:</p>

                    <ul>
                        <li>Contable: contabiliza las operaciones a través de tus CFDI, además de generar los archivos de envío de la ocntabilidad electrónica.</li>
                        <li>Nómina: Cálculo, control y timbrado de CFDI de nómina.</li>
                        <li>Facturación: Emite los CFDI cumpliendo con los requerimientos técnicos que emite el SAT.</li>
                    </ul>
                `;
        });
      }
    }, 1500);
  }, []);

  return (
    <Fragment>
      <div class="services-sect-container" id="servicios">
        <div class="ita-srv-tittle">
          <div class="ita-srv-span">
            <span>¿Qué ofrecemos?</span>
          </div>
          <div class="tcircles">
            <div className="circles">
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
            </div>
          </div>
        </div>

        <div class="ita-services">
          <div class="ita-services-columns">
            <div class="srv-img first" id="first-serv">
              <img src={FISCAL}></img>
              <h2>Fiscal</h2>
            </div>

            <div class="srv-img second" id="second-serv">
              <img src={ALIANZAS}></img>
              <h2>Alianzas</h2>
            </div>

            <div class="srv-img third" id="third-serv">
              <img src={ADMIN}></img>
              <h2>Administrativo</h2>
            </div>

            <div class="triangles-sec1" id="triangles-sec1">
              <div class="srv-img first-triangle" id="first-triangle">
                <div class="tleft-triangle"></div>
              </div>
              <div class="srv-img second-triangle" id="second-triangle">
                <div class="hidden-triangle"></div>
              </div>
              <div class="srv-img third-triangle" id="third-triangle">
                <div class="hidden-triangle"></div>
              </div>
            </div>

            <div class="ita-srv-info-container" id="serv-info">
              <div class="ita-srv-info">
                <div class="ita-srv-info-tittle" id="serv-tittle"></div>
                <div class="ita-srv-info-text" id="info-text"></div>
              </div>
            </div>

            <div class="srv-img fourth" id="fourth-serv">
              <img src={CONTA}></img>
              <h2>Contabilidad</h2>
            </div>

            <div class="srv-img fifth" id="fifth-serv">
              <img src={TECINF}></img>
              <h2>Tecnología informática</h2>
            </div>

            <div class="triangles-sec2">
              <div class="srv-img fourth-triangle" id="fourth-triangle">
                <div class="hidden-triangle"></div>
              </div>
              <div class="srv-img sixth-triangle" id="sixth-triangle">
                <div class="hidden-triangle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Servicios;
