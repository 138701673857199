import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const Navbar = (props) => {
  const { routerCirclesState, setRouterCirclesState } = props;
  const [navBackground, setNavBackground] = useState(false);
  useEffect(() => {}, []);
  const aboutFunction = (numberCircle) => {
    setRouterCirclesState(() => {
      console.log("ARREGLO DEVUELTO");
      return routerCirclesState.map((item) =>
        item.id == numberCircle
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      );
    });
  };
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavBackground(true);
    } else {
      setNavBackground(false);
    }
    // console.log(window.scrollY);
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <NavbarComponent navBackground={navBackground}>
      <nav className={navBackground ? "active-nav" : ""}>
        <div>
          <img
            src="https://images.pexels.com/photos/1337380/pexels-photo-1337380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="image"
            width="50"
            className="logo-page"
          />
        </div>
        <div className="links-box">
          <Link
            // activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            isDynamic={true}
          >
            Home
          </Link>
          <Link
            // activeClass="active"
            to="quienes-somos-dos"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            isDynamic={true}
          >
            Acerca de
          </Link>
          <Link
            to="servicios"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            isDynamic={true}
          >
            ¿Qué ofrecemos?
          </Link>
          <Link
            to="porque-nosotros"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            isDynamic={true}
          >
            ¿Por qué nosotros?
          </Link>
          <Link
            to="contacto-dos"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            isDynamic={true}
          >
            Contacto
          </Link>
        </div>
      </nav>
    </NavbarComponent>
  );
};

export default Navbar;

const NavbarComponent = styled.nav`
  position: fixed;
  transition: all linear 0.4s;
  right: 0;
  width: 100%;
  background: ${(props) => (props.navBackground ? "white" : "")};
  box-shadow: ${(props) =>
    props.navBackground ? "1px 3px 11px -3px rgba(0, 0, 0, 0.47)" : ""};
  z-index: 10;
  a {
    cursor: pointer;
  }

  .links-box {
    display: flex;
    align-items: center;
    font-family: "MyriadPro-Regular";
  }
  .link-box a {
    font-family: "MyriadPro-Regular" !important;
  }
  /* NavLink {
    color: black;
  } */
  a {
    /* color: green;
    font-size: 5rem; */
    font-size: 1.2rem;
    margin-right: 1rem;
    color: #1e2e59;
  }
  .active {
    color: #54c86e;
    font-size: 1.2rem;
    font-weight: bold;
    /* border: red solid 1px; */
  }
  .inactive {
    color: black;
  }
  max-width: 100vw;
  /* border: black solid 1px; */
  overflow: hidden;

  @media screen and (max-width: 900px) {
    display: none;
  }
  .logo-page {
    visibility: hidden;
  }
  nav {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    justify-content: space-between;
    align-items: center;
  }
  img {
  }
  ul {
    display: flex;
    margin-right: 1.5rem;
  }
  ul li {
    margin-right: 1.2rem;
    font-size: 1.2rem;
    color: #1e2e59;
  }
`;
