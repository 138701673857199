import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ButtonRouter = (props) => {
  const {
    textBoxCircle,
    numberCircle,
    isActive,
    pageUrl,
    routerCirclesState,
    setRouterCirclesState,
  } = props;

  const setActiveNumber = () => {
    console.log("Numero tocado: ");
    console.log(numberCircle);
    console.log("Arreglo devuelto");
    console.log(
      routerCirclesState.map((item) =>
        item.id == numberCircle
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );

    setRouterCirclesState(() => {
      console.log("ARREGLO DEVUELTO");
      return routerCirclesState.map((item) =>
        item.id == numberCircle
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      );
    });
  };
  return (
    <ButtonRouterStyle>
      <Link to={pageUrl} className="my-link">
        <div className="circle-component" onClick={setActiveNumber}>
          <div
            className="circle"
            style={{ backgroundColor: isActive ? "#54C86E" : "#0a0a2c" }}
          >
            <h4>{numberCircle}</h4>
          </div>
          <div
            className="text-box"
            style={{ color: isActive ? "#54C86E" : "#0a0a2c" }}
          >
            {textBoxCircle}
          </div>
        </div>
      </Link>
    </ButtonRouterStyle>
  );
};

export default ButtonRouter;

const ButtonRouterStyle = styled.div`
  .my-link {
    color: inherit;
  }
  @media screen and (max-width: 900px) {
    .circle {
    }
    .text-box {
      font-size: 0.75rem;
    }
  }

  width: 140px;
  margin-bottom: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .circle-component {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
  .circle h4 {
    color: white;
  }
`;
