import React, { useState, useEffect, Fragment } from "react";

import Carousel from "react-material-ui-carousel";

import "../css/Noticias.css";

import NOTICIA1 from "../media/pictures/noticia1.jpg";
import NOTICIA2 from "../media/pictures/noticia2.jpg";
import NOTICIA3 from "../media/pictures/noticia3.jpg";
import NOTICIA4 from "../media/pictures/noticia4.jpg";
import NOTICIA5 from "../media/pictures/noticia5.jpg";
import NOTICIA6 from "../media/pictures/noticia6.jpg";
import NOTICIA7 from "../media/pictures/noticia7.jpg";
import NOTICIA8 from "../media/pictures/noticia8.jpg";

const Noticias = () => {
  let ndate = "";

  useEffect(() => {
    const fechaActual = new Date();
    const opciones = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    ndate = fechaActual.toLocaleDateString("es-Es", opciones);
    setTimeout(() => {
      document.getElementById("ndate").innerHTML = ndate;
    }, 1000);
  }, []);

  const links = (link) => {
    const link1 =
      "https://www.sat.gob.mx/noticias/20572/conoce-las-practicas-indebidas-en-la-emision-de-facturas";
    const link2 =
      "https://www.sat.gob.mx/noticias/01993/correos-falsos-identificados";
    const link3 =
      "https://www.sat.gob.mx/noticias/90949/conoce-el-vencimiento-de-tu-declaracion-bimestral";
    const link4 =
      "https://www.sat.gob.mx/noticias/18903/%3Ftienes-empleados%3F-utiliza-nomina-mis-cuentas";
    const link5 =
      "https://www.sat.gob.mx/noticias/70404/conoce-los-cambios-en-las-guias-de-llenado";
    const link6 =
      "https://www.sat.gob.mx/noticias/37385/cancela-tu-permiso-de-importacion-temporal";
    const link7 =
      "https://www.sat.gob.mx/noticias/06843/eliminacion-de-la-factura-global-para-hidrocarburos";
    const link8 =
      "https://www.sat.gob.mx/noticias/80755/recibe-notificaciones-electronicas-en-tu-buzon-tributario";

    switch (link) {
      case 1:
        window.open(link1);
        break;

      case 2:
        window.open(link2);
        break;

      case 3:
        window.open(link3);
        break;

      case 4:
        window.open(link4);
        break;

      case 5:
        window.open(link5);
        break;

      case 6:
        window.open(link6);
        break;

      case 7:
        window.open(link7);
        break;

      case 8:
        window.open(link8);
        break;
    }
  };

  // carousel
  const carouselInfo = [
    {
      id: 1,
      img: NOTICIA1,
      tittle: "Conoce las prácticas indebidas en la emisión de facturas",
    },
    {
      id: 2,
      img: NOTICIA2,
      tittle: "Correos falsos identificados",
    },
    {
      id: 3,
      img: NOTICIA3,
      tittle: "Conoce el vencimiento de tu declaración bimestral",
    },
    {
      id: 4,
      img: NOTICIA4,
      tittle: "Utiliza Nómina Mis cuentas",
    },
    {
      id: 5,
      img: NOTICIA5,
      tittle: "Conoce los cambios en las guías de llenado",
    },
    {
      id: 6,
      img: NOTICIA6,
      tittle: "Cancela tu permiso de importación temporal",
    },
    {
      id: 7,
      img: NOTICIA7,
      tittle: "Eliminación de la factura global para hidrocarburos",
    },
    {
      id: 7,
      img: NOTICIA8,
      tittle: "Recibe notificaciones electrónicas en tu Buzón Tributario",
    },
  ];

  const Item = (item) => {
    return (
      <div class="carousel">
        <p>{item.tittle}</p>
        <img src={item.img} />

        <span
          class="carousel-btn"
          onClick={(e) => {
            links(item.id);
          }}
        >
          Saber más
        </span>
      </div>
    );
  };

  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return (
      <div class="ita-news">
        <div class="ita-srv-tittle">
          <div class="ita-srv-span">
            <span>Noticias</span>
          </div>
          <div class="tcircles">
            <div className="circles">
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
              <div className="small-green-circle"></div>
            </div>
          </div>
        </div>

        <div class="news-date">
          <span id="ndate">{ndate}</span>
        </div>
        <Carousel>{carouselInfo.map((item) => Item(item))}</Carousel>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div class="ita-news">
          <div class="ita-srv-tittle">
            <div class="ita-srv-span">
              <span>Noticias</span>
            </div>
            <div class="tcircles">
              <div className="circles">
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
              </div>
            </div>
          </div>

          <div class="news-date">
            <span id="ndate">{ndate}</span>
          </div>

          <div class="ita-news-first">
            <div class="ita-news-sec1">
              <div class="news1-f">
                <div class="ita-news-text">
                  <img src={NOTICIA1}></img>
                  <h3>
                    Conoce las prácticas indebidas en la emisión de facturas
                  </h3>
                  <p>
                    Decálogo de malas prácticas El Servicio de Administración
                    Tributaria (SAT) informa que, con la finalidad de proteger
                    el derecho que tienen los contribuyentes de deducir y
                    acreditar impuestos, a continuación se indican las prácticas
                    indebidas detectadas en la emisión de facturas, los cuales
                    te servirán como una guía de acuerdo a los lineamientos
                    establecidos y evitar ser acreedor a una sanción.
                  </p>
                  <span
                    onClick={(e) => {
                      links(1);
                    }}
                  >
                    Saber más
                  </span>
                </div>
                <div class="ita-news-text">
                  <img src={NOTICIA4}></img>
                  <h3>¿Tienes empleados? Utiliza Nómina Mis cuentas</h3>
                  <p>
                    Decálogo de malas prácticas El Servicio de Administración
                    Tributaria (SAT) informa que, con la finalidad de proteger
                    el derecho que tienen los contribuyentes de deducir y
                    acreditar impuestos, a continuación se indican las prácticas
                    indebidas detectadas en la emisión de facturas, los cuales
                    te servirán como una guía de acuerdo a los lineamientos
                    establecidos y evitar ser acreedor a una sanción.
                  </p>
                  <span
                    onClick={(e) => {
                      links(2);
                    }}
                  >
                    Saber más
                  </span>
                </div>
              </div>
              <div class="news2-f">
                <div class="ita-news-text">
                  <img src={NOTICIA2}></img>
                  <h3>Correos falsos identificados</h3>
                  <p>
                    Para facilitarte la identificación de algún correo
                    electrónico que sospeches pueda ser falso, hemos incorporado
                    en gobmx un buscador...
                  </p>
                  <span
                    onClick={(e) => {
                      links(3);
                    }}
                  >
                    Saber más
                  </span>
                </div>
                <div class="ita-news-text">
                  <img src={NOTICIA3}></img>
                  <h3>Conoce el vencimiento de tu declaración bimestral</h3>
                  <p>
                    Recuerda presentar tu declaración bimestral en tiempo y
                    forma.
                  </p>
                  <span
                    onClick={(e) => {
                      links(4);
                    }}
                  >
                    Saber más
                  </span>
                </div>
                <div class="ita-news-text">
                  <img src={NOTICIA5}></img>
                  <h3>Conoce los cambios en las guías de llenado</h3>
                  <p>
                    El 14 de octubre del 2019, se actualizaron las guías de
                    llenado del Anexo 20 y complemento de nómina. Los cambios
                    entran en vigor a partir del 1 de enero de 2020.
                  </p>
                  <span
                    onClick={(e) => {
                      links(4);
                    }}
                  >
                    Saber más
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ita-news-second">
            <div class="ita-news3-s">
              <div class="ita-news-text">
                <img src={NOTICIA6}></img>
                <h3>Cancela tu permiso de importación temporal</h3>
                <p>
                  Ya puedes cancelar el permiso de importación temporal para tu
                  vehículo, casa rodante o embarcación, ¡aprovecha la primera
                  jornada de cancelación de permisos en Estados Unidos!
                </p>
                <span
                  onClick={(e) => {
                    links(6);
                  }}
                >
                  Saber más
                </span>
              </div>
              <div class="ita-news-text">
                <img src={NOTICIA7}></img>
                <h3>
                  Recibe notificaciones electrónicas en tu Buzón Tributario
                </h3>
                <p>
                  Una de las formas que tiene el SAT para notificarte es
                  electrónicamente a través de tu Buzón Tributario...
                </p>
                <span
                  onClick={(e) => {
                    links(7);
                  }}
                >
                  Saber más
                </span>
              </div>
            </div>
            <div class="ita-news4-s">
              <div class="ita-news-text">
                <img src={NOTICIA8}></img>
                <h3>
                  Recibe notificaciones electrónicas en tu Buzón Tributario
                </h3>
                <p>
                  Una de las formas que tiene el SAT para notificarte es
                  electrónicamente a través de tu Buzón Tributario.
                </p>
                <span
                  onClick={(e) => {
                    links(8);
                  }}
                >
                  Saber más
                </span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};

export default Noticias;
