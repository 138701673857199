import React, { useState, useEffect } from "react";
import ButtonRouter from "../components/ButtonRouter";
import styled from "styled-components";

const ContentRouter = (props) => {
  const { routerCirclesState, setRouterCirclesState } = props;
  useEffect(() => {
    console.log("CONTENT ROUTER");
    console.log(routerCirclesState);
  }, []);
  return (
    <ContentRouterStyle>
      <div className="box-circles">
        <ButtonRouter
          numberCircle={1}
          textBoxCircle={"¿Quiénes somos?"}
          isActive={routerCirclesState.find((item) => item.id == 1).isActive}
          pageUrl={"/quienes-somos"}
          routerCirclesState={routerCirclesState}
          setRouterCirclesState={setRouterCirclesState}
        />
        <ButtonRouter
          numberCircle={2}
          textBoxCircle={"¿Servicios?"}
          isActive={routerCirclesState.find((item) => item.id == 2).isActive}
          pageUrl={"/servicios"}
          routerCirclesState={routerCirclesState}
          setRouterCirclesState={setRouterCirclesState}
        />
        <ButtonRouter
          numberCircle={3}
          textBoxCircle={"Nuestro trabajo"}
          isActive={routerCirclesState.find((item) => item.id == 3).isActive}
          pageUrl={"/porque-nosotros"}
          routerCirclesState={routerCirclesState}
          setRouterCirclesState={setRouterCirclesState}
        />
        <ButtonRouter
          numberCircle={4}
          textBoxCircle={"Contacto"}
          isActive={routerCirclesState.find((item) => item.id == 4).isActive}
          pageUrl={"/contacto"}
          routerCirclesState={routerCirclesState}
          setRouterCirclesState={setRouterCirclesState}
        />
      </div>
    </ContentRouterStyle>
  );
};

export default ContentRouter;

const ContentRouterStyle = styled.div`
  margin-top: 1.2rem;
  @media screen and (max-width: 900px) {
    /* border: red solid 1px; */
    display: none;
  }
  .box-circles {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
