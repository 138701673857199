import React from "react";
import { Fragment } from "react";

//style
import "../css/Contact.css";

import WHATSAPP from '../media/icons/WhatsApp_icon.png'

const Contacto = () => {
  return (
    <Fragment>
      <div id="contacto-dos">
        <div class="ita-contact-container">

          <div class="ita-srv-tittle">
            <div class="ita-srv-span">
              <span>Contáctanos</span>
            </div>
            <div class="tcircles">
              <div className="circles">
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
                <div className="small-green-circle"></div>
              </div>
            </div>
          </div>

          <div class="ita-contact-sec1">
            <div class="ita-contact-info">
              <div class="ita-cont-text">
                <h2>Dirección</h2>
                <p>Hornillos #5, Col. Santa Cecilia, Tlalnepantla 54130</p>
              </div>
              <div class="ita-cont-text">
                <h2>Correo Electrónico</h2>
                <p>mtrujillo@itacontadores.com</p>
              </div>
              <div class="ita-cont-text">
                <h2>Teléfono</h2>
                <p>55 59 20 23 11</p>
              </div>
            </div>
            <div class="ita-contact-form">
              <div class="contact-us-form">
                <form
                  action="https://formsubmit.co/mtrujillo@itacontadores.com"
                  method="POST"
                >
                  <div class="contact-us-form-name-inp">
                    <input
                      type="text"
                      placeholder="Nombre"
                      name="Nombre"
                      required
                      // onChange={(e) => {
                      //     mailData.name = e.target.value
                      // }}
                    ></input>
                  </div>

                  <div class="contact-us-form-lastname-inp">
                    <input
                      type="text"
                      placeholder="Teléfono"
                      name="Teléfono"
                      required
                      // onChange={(e) => {
                      //     mailData.lastName = e.target.value
                      // }}
                    ></input>
                  </div>

                  <div class="contact-us-form-email-inp">
                    <input
                      type="text"
                      placeholder="Correo Electrónico"
                      name="Email"
                      required
                      // onChange={(e) => {
                      //     mailData.email = e.target.value
                      // }}
                    ></input>
                  </div>

                  <div class="contact-us-form-business-inp">
                    <input
                      type="text"
                      placeholder="Empresa"
                      name="Empresa"
                      required
                      // onChange={(e) => {
                      //     mailData.business = e.target.value
                      // }}
                    ></input>
                  </div>

                  <div class="contact-us-form-message-txta">
                    <textarea
                      type="text"
                      placeholder="Mensaje"
                      name="Mensaje"
                      required
                      // onChange={(e) => {
                      //     mailData.message = e.target.value
                      // }}
                    ></textarea>
                  </div>

                  <div
                    class="contact-us-form-send-btn"
                    // onClick={
                    //     (e) => {
                    //         newEmail()
                    //     }}
                  >
                    <button class="btn-enviar" type="submit">
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15038.032280108257!2d-99.20753416126013!3d19.562722312773385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21d7dbfe6c147%3A0x95aec08f758aae98!2sSta.%20Cecilia%2C%20Sta%20Cecilia%20Acatitlan%2C%2054130%20Tlalnepantla%20de%20Baz%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1670259176130!5m2!1ses-419!2smx"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      

      <div class="whatsapp-icon" onClick={(e) => {window.open("https://wa.me/5550728828")}}>
        <img src={WHATSAPP}></img>
        Ayuda
      </div>
    </Fragment>
  );
};

export default Contacto;